<div>
    <h3>{{data.title}}</h3>
</div>
<div class="kt-form">
    <div class="kt-portlet__body container">
        <mat-spinner [diameter]="50" class="loader" *ngIf="loading"></mat-spinner>
        <div fxLayout="row" *ngIf="!loading" fxLayoutGap="0.5vw">
            <div fxFlex fxLayout="column" fxGrow="0" fxLayoutAlign="start center">
                <figure class="card-profile-image" fxFlex fxGrow="0">
                    <img [src]="pictureLink" (click)="showImageCropper()"
                        style="width: 100px; height:100px; cursor: pointer;"
                        class="circle z-depth-2 responsive-img activator">
                </figure>
                <button mat-raised-button fxGrow="0" fxFlex (click)="showImageCropper()">{{'Select Image' |
                    translate}}</button>
            </div>
            <div fxLayout="column" fxLayoutGap="0.5vw" style="width: 100%;">
                <mat-form-field appearance="fill" fxFlex>
                    <mat-label>{{'First Name' | translate}}</mat-label>
                    <input matInput placeholder="" required [formControl]="firstNameFormControl">
                    <mat-error *ngIf="firstNameFormControl.hasError('required')">
                        {{'First name is required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex>
                    <mat-label>{{'Last Name' | translate}}</mat-label>
                    <input matInput placeholder="" required [formControl]="lastNameFormControl">
                    <mat-error *ngIf="lastNameFormControl.hasError('required')">
                        {{'Last name is required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex>
                    <mat-label>{{'Email' | translate}}</mat-label>
                    <input matInput placeholder="" readonly [value]="email">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="text-right">
        <div>
            <br />
            <button mat-raised-button (click)="closeDialog()">{{'Cancel' |
                translate}}</button>&nbsp;
            <button mat-raised-button color="primary" (click)="updateUser()">{{'Update' |
                translate}}</button>
        </div>
    </div>
</div>