import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import { AuthenticationService } from 'src/app/core/services/auth.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from 'src/app/core/services/request.service';
import { environment } from 'src/environments/environment';
import { LayoutUtilsService } from 'src/app/core/services/layout-utils.service';
import { EditProfileDialogComponent } from '../modals/edit-profile-dialog/edit-profile-dialog.component';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('snav') sidenav!: MatSidenav;

    private _mobileQueryListener: () => void;
    mobileQuery: MediaQueryList;
    userName: string = "";
    isAdmin: boolean = false;
    isSuperAdmin: boolean = false;
    currentRoute: string = '';
    clients: any = [];
    private subscriptions: any[] = <any>[];
    // private autoLogoutSubscription: Subscription = new Subscription;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        public spinnerService: SpinnerService,
        private authService: AuthenticationService, private layoutUtilsService: LayoutUtilsService,
        private router: Router, private dialog: MatDialog, private translate: TranslateService, private requestService: RequestService) {

        this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);

        router.events.subscribe(data => {
            if (data instanceof NavigationEnd) {
                this.currentRoute = data.url;
            }
        });
    }

    ngOnInit(): void {
        const user = this.authService.getCurrentUser();
        // console.log('user', user)
        if (!user) {
            setTimeout(() => {
                this.requestService.logout();
            }, 200);
        }
        else {
            this.isAdmin = user.resources.find(i => i._id === environment.customKeys.roleAdmin);
            this.isSuperAdmin = user.isSuperAdmin;
            this.userName = user.fullName;

            // Auto log-out subscription
            // const timer$ = timer(2000, 5000);
            // this.autoLogoutSubscription = timer$.subscribe(() => {
            //     this.authGuard.canActivate();
            // });

            this.requestService.getDataList('user', {
                page: 1, term: '', filter: {
                    "$and": [
                        { "resources._id": { "$eq": environment.customKeys.roleAdmin } },
                        { "_id": { "$ne": user._id } },
                    ]
                }, orderBy: 'name', orderDir: 'asc', fieldKeys: ['email', 'name']
            }, (data, error) => {
                if (data) {
                    this.clients = data.results;
                }
                else if (error) {
                    this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
        // this.autoLogoutSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
        // if (this.currentRoute.indexOf('/flow') !== -1)
        //     this.sidenav.close();
    }

    onLoggedout() {
        this.requestService.logOutApi();
    }

    openEditProfile() {
        const dialog = this.dialog.open(EditProfileDialogComponent, {
            width: 'fit-content',
            disableClose: true,
            data: {
                title: 'Edit Profile'
            }
        });
        dialog.afterClosed().subscribe(value => {

        });
    }

    // resetPassword() {

    // }
}
