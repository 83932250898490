import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {

    private subscriptions: any[] = <any>[];
    private selectedOptions: any = [];

    public conditionNodes: any = [];
    public instance: any = {};
    public step: any = {};
    public character: any = {};
    public selectedOption: any = {};
    public selectedOptionReflection: any = undefined;
    public selectedOptionFeedback: any = undefined;
    public history: any = [];
    public previousNodeId: string = undefined;
    public node: any = undefined;
    public currentUser: any = undefined;
    public score: number = 0;
    public showFeedback: boolean = false;
    public feedbackIndex: number = 0;
    public showCongrats: boolean = false;

    constructor(private activatedRoute: ActivatedRoute, private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService) { }

    ngOnInit(): void {
        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((value) => {
            if (value) {
                this.currentUser = value;
            }
        }));

        this.subscriptions.push(this.activatedRoute.params.subscribe(async (params: any) => {
            if (params.hasOwnProperty('instanceId')) {
                await this.getInstance(params.instanceId).then((data: any) => {
                    this.instance = data;
                    this.history = data.flow.history;
                    this.score = data.points;
                    this.selectedOptions = data.flow.selectedOptions;
                    this.conditionNodes = this.instance.flow.nodes.filter(i => i.type === 'condition');
                }).catch(error => {
                    this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                });
                // await this.getCharacter(this.instance.characterId._id).then((data) => {
                //     this.character = data;
                // }).catch(error => {
                //     this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                // });
                // await this.getOpeningAct(this.instance.openingActId._id).then((data: any) => {
                //     this.openingAct = {
                //         content: this.replaceX(this.character.name, this.character.gender, data.results.maleContent, data.results.femaleContent, data.results.otherContent)
                //     };
                // }).catch(error => {
                //     this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                // });

                this.populateFeedback();
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    private getInstance(instanceId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord(instanceId, 'instance', async (data, error) => {
                if (data)
                    resolve(data.results);
                if (error) {
                    reject(error);
                }
            });
        });
    }

    // private getCharacter(characterId: string): Promise<void> {
    //     return new Promise((resolve, reject) => {
    //         this.requestService.getRecord(characterId, 'character', (data: any, error: any) => {
    //             if (data) {
    //                 resolve(data.results);
    //             }
    //             if (error) {
    //                 reject(error);
    //             }
    //         });
    //     });
    // }

    private replaceX(characterName, gender, male, female, other): string {
        if (gender === 'male')
            return male?.replace(/\[X\]/g, characterName);
        else if (gender === 'female')
            return female?.replace(/\[X\]/g, characterName);
        else
            return other?.replace(/\[X\]/g, characterName);
    }

    // private getOpeningAct(openingActId: string): Promise<void> {
    //     return new Promise((resolve, reject) => {
    //         this.requestService.getRecord(openingActId, 'openingacts', (data: any, error: any) => {
    //             if (data) {
    //                 resolve(data);
    //             }
    //             if (error)
    //                 reject(error);

    //             // this.populateStep();
    //         });
    //     });
    // }

    next() {
        this.feedbackIndex++;
        if (this.feedbackIndex === this.conditionNodes.length) {
            this.showCongratsScreen();
        }
        else {
            this.populateFeedback();
        }
    }

    previous() {
        this.feedbackIndex--;
        this.populateFeedback();
    }

    private showCongratsScreen() {
        this.showCongrats = true;
    }

    private async populateFeedback() {
        this.node = this.conditionNodes[this.feedbackIndex];

        let step: any = {}
        if (this.node?.type === 'condition') {
            step.content = this.replaceX(this.character.name, this.character.gender, this.node.name.male, this.node.name.female, this.node.name.other);

            step.options = [];
            this.node.conditions.forEach(option => {
                let opt = this.node.metadata.fields.find(i => i.fieldId === option.fieldId);

                step.options.push({
                    fieldId: opt.fieldId,
                    label: this.replaceX(this.character.name, this.character.gender, opt.fieldValue.male, opt.fieldValue.female, opt.fieldValue.other),
                    score: opt.fieldScore,
                    tags: opt.fieldTags,
                    feedback: opt.feedback
                });
            });

            this.selectedOption = {};
            let node = this.selectedOptions.find(i => i.nodeId === this.node.id);
            if (node) {
                this.selectedOption = {
                    fieldId: node.fieldId,
                    score: node.fieldScore
                };
                this.selectedOptionReflection = node.reflection;
                this.selectedOptionFeedback = this.node.metadata.fields.find(i => i.fieldId === node.fieldId)?.feedback;
            }

            step.background = this.node.metadata?.media[0]?.background?.url;
            step.audio = this.node.metadata?.media[0]?.audio?.url;
            step.video = this.node.metadata?.media[0]?.video?.url;

            this.step = step;
        }
        // else if (this.node?.type === 'end') {
        //     step.content = 'Completed';
        //     this.step = step;
        // }
    }
}