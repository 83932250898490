<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onDismiss()">{{noButton}}</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">{{yesButton}}</button>
  <button mat-button (click)="onCancel()" *ngIf="cancelButton">{{cancelButton}}</button>
</div>