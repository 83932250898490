import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FlowService } from "src/app/core/services/flow.service";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";
import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";

export interface InstanceListTable {
    _id: string;
    versionId: string;
    createdAt: string;
    points: string;
    score_percentage: string;
    status: string;
}

@Component({
    selector: 'app-instance-list-modal',
    templateUrl: './instance-list-modal.component.html',
    styleUrls: ['./instance-list-modal.component.scss']
})
export class InstanceListDialogComponent implements OnInit {
    pageIndex: number = 1;
    pageSize: number = 10;

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    public loading: boolean = false;
    public displayedColumns: string[] = ['createdAt', 'updatedAt', 'points', 'score', 'status', 'character', 'tools'];
    public datasource: any = undefined;

    constructor(public dialogRef: MatDialogRef<InstanceListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private requestService: RequestService, private dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private flowService: FlowService) {
    }

    ngOnInit(): void {
        this.getInstances();
    }

    getInstances(event?: PageEvent) {
        this.loading = true;
        this.pageIndex = event?.pageIndex || 0;
        this.pageSize = event?.pageSize || 10;

        this.requestService.postRequest({
            page: this.pageIndex + 1,
            count: this.pageSize,
            filter: {
                "$and": [
                    { "flowId._id": { "$eq": this.data.flowId } },
                    { "createdBy": { "$eq": this.data.userId } },
                ]
            },
            order: [{ "field": "updatedAt", "order": "desc" }],
            fields: ['endedAt', 'createdAt', 'points', 'status', 'score_percentage', 'characterId', 'createdBy'],
        },
            'instance/search', (data, error) => {
                if (data) {
                    this.datasource = new MatTableDataSource<InstanceListTable>(data.results);
                    // this.datasource.paginator = this.paginator;
                    this.paginator.length = data.pagination.total;
                }
                if (error) {
                    this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                }
                this.loading = false;
            });
    }

    public deleteInstance(instanceId: string) {
        const dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: this.translate.instant('Are you sure you want to delete this instance?'),
                title: ''
            }, width: 'fit-content'
        });
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.loading = true;
                this.requestService.postRequest({}, 'instance/' + instanceId + '/delete', (data, error) => {
                    if (data) {
                        this.flowService.flowListSubject.next(true);
                        this.layoutUtilsService.showNotification(this.translate.instant('Deleted Successfully'), this.translate.instant('Dismiss'));
                        this.getInstances();
                    }
                    if (error) {
                        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                        this.loading = false;
                    }
                });
            }
        });
    }

    public viewInstance(instanceId: string, status: string) {
        if (status === 'completed')
            this.router.navigate(['flow/' + this.data.flowId + '/instance/' + instanceId + '/preview']);
        else
            this.router.navigate(['flow/' + this.data.flowId + '/instance/' + instanceId]);
        this.dialogRef.close();
    }
}