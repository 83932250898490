import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ConfettiService } from "src/app/core/services/confetti.service";

@Component({
    selector: 'app-congrats-screen',
    templateUrl: './congrats-screen.component.html',
    styleUrls: ['./congrats-screen.component.scss']
})
export class CongratsScreenComponent implements OnInit, OnDestroy {

    public showCongrats: boolean = false;

    @Input() score: number = 0;

    constructor(private confettiService: ConfettiService, private router: Router) { }

    ngOnInit(): void {
        this.confettiService.party();
    }

    ngOnDestroy(): void {
        this.confettiService.stop();
    }

    gotoDashboard() {
        this.router.navigate(['/']);
    }
}