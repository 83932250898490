import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";;

@Component({
    selector: 'app-feedback-screen',
    templateUrl: './feedback-screen.component.html',
    styleUrls: ['./feedback-screen.component.scss']
})
export class FeedbackScreenComponent implements OnInit, OnDestroy {

    public showCongrats: boolean = false;

    @Input() score: number = 0;
    @Input() flowId: string = undefined;
    @Input() instanceId: string = undefined;

    constructor(private router: Router) { }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

    gotoFeedback() {
        this.router.navigate(['/flow/' + this.flowId + '/instance/' + this.instanceId + '/feedback']);
    }
}