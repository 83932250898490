import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RequestService } from 'src/app/core/services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService } from 'src/app/core/services/layout-utils.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { FlowCharacterSelectionDialogComponent } from '../flow/flow-character-selection-modal/flow-character-selection-modal.component';
import { InstanceListDialogComponent } from '../instance-list-modal/instance-list-modal.component';
import { FlowService } from 'src/app/core/services/flow.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  pageIndexAvailable: number = 1;
  pageIndexPast: number = 1;
  pageSizeAvailable: number = 9;
  pageSizePast: number = 9;
  public flows: any = [];
  public isAvailableSelected: boolean = true;
  public availableFlows: any = [];
  public pastFlows: any = [];

  // private allAvailableFlows: any = [];
  // private allPastFlows: any = [];
  private currentUser: any;
  private subscriptions: any = [];

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('paginatorAvailable') paginatorAvailable!: MatPaginator;
  @ViewChild('paginatorPast') paginatorPast!: MatPaginator;

  constructor(private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private router: Router, private dialog: MatDialog, private flowService: FlowService) {
  }

  async ngOnInit() {
    this.subscriptions.push(this.requestService.currentUserSubject.subscribe((value) => {
      if (value) {
        this.currentUser = value;
      }
    }));

    this.getFlowsAndPopulate();
    this.getPastFlowsAndPopulate();

    this.subscriptions.push(this.flowService.flowListSubject.subscribe(async value => {
      if (value) {
        await this.getFlows().then((data: any) => {
          this.populateFlows(data);
        }).catch(error => {
          this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
        });
        await this.getPastFlows().then((data: any) => {
          this.populatePastFlows(data);
        }).catch(error => {
          this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
        });
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          this.getFlowsAndPopulate(undefined, this.searchInput.nativeElement.value);
          this.getPastFlowsAndPopulate(undefined, this.searchInput.nativeElement.value);
        } catch (e) { }
      })
    ).subscribe());
  }

  async getFlowsAndPopulate(event?: PageEvent, term: string = this.searchInput?.nativeElement.value) {
    await this.getFlows(false, event, term).then((data: any) => {
      this.populateFlows(data);
    }).catch(error => {
      this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
    });
  }

  async getPastFlowsAndPopulate(event?: PageEvent, term: string = this.searchInput?.nativeElement.value) {
    await this.getPastFlows(event, term).then((data: any) => {
      this.populatePastFlows(data);
    }).catch(error => {
      this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
    });
  }

  private populateFlows(flows: any) {
    this.availableFlows = flows.results;
    // this.allAvailableFlows = this.availableFlows;
    this.paginatorAvailable.length = flows.pagination.total;

    this.availableFlows.map(flow => {
      if (flow.instances?.length === 0 || flow.submissionType === 'multiple_submission')
        flow.showMenu = true;
      else
        flow.showMenu = false;

      if (flow.instances?.length && flow.instances.find(i => i.status?.toLowerCase() === 'in progress'))
        flow.hasIncompleteInstance = true;
      else
        flow.hasIncompleteInstance = false;

      if ((flow.openingActIds.length > 1 || flow.characterIds.length > 1) && flow.submissionType === 'multiple_submission')
        flow.isVariable = true;
      else
        flow.isVariable = false;
    });

    if (this.isAvailableSelected)
      this.flows = this.availableFlows;
  }

  private populatePastFlows(flows: any) {
    this.pastFlows = flows.results;
    // this.allPastFlows = this.pastFlows;
    this.paginatorPast.length = flows.pagination.total;

    this.pastFlows.map(flow => {
      if (flow.instances?.length === 0 || flow.submissionType === 'multiple_submission')
        flow.showMenu = true;
      else
        flow.showMenu = false;

      if (flow.instances?.length && flow.instances.find(i => i.status?.toLowerCase() === 'in progress'))
        flow.hasIncompleteInstance = true;
      else
        flow.hasIncompleteInstance = false;

      if ((flow.openingActIds.length > 1 || flow.characterIds.length > 1) && flow.submissionType === 'multiple_submission')
        flow.isVariable = true;
      else
        flow.isVariable = false;
    });

    if (!this.isAvailableSelected)
      this.flows = this.pastFlows;
  }

  getFlows(setFlows: boolean = false, event?: PageEvent, term?: string): Promise<void> {
    this.pageIndexAvailable = event?.pageIndex || 0;
    this.pageSizeAvailable = event?.pageSize || 9;

    return new Promise((resolve, reject) => {
      this.requestService.postRequest({
        isPastDueDate: false,
        page: this.pageIndexAvailable + 1,
        count: this.pageSizeAvailable,
        term: term || '', termfields: ['_id', 'name', 'description'],
        order: [{ "field": "updatedAt", "order": "desc" }],
        fields: ['name', 'flowId', 'versionId', 'default', 'flow', 'openingActIds', 'characterIds', 'organizationId', 'tags', 'fileLink', 'description', 'optactsAvailability', 'charactersAvailability', 'averageCompletion', 'allowDecisionFeedback', 'allowReflection']
      }, 'user/flow/' + this.currentUser._id + '/search', (data, error) => {
        if (data) {
          resolve(data.results);
        }
        if (error) {
          reject(error);
        }
      });
    });
  }

  getPastFlows(event?: PageEvent, term?: string): Promise<void> {
    this.pageIndexPast = event?.pageIndex || 0;
    this.pageSizePast = event?.pageSize || 9;

    return new Promise((resolve, reject) => {
      this.requestService.postRequest({
        isPastDueDate: true,
        page: this.pageIndexPast + 1,
        count: this.pageSizePast,
        term: term || '', termfields: ['_id', 'name', 'description'],
        order: [{ "field": "updatedAt", "order": "desc" }],
        fields: ['name', 'flowId', 'versionId', 'default', 'flow', 'openingActIds', 'characterIds', 'organizationId', 'tags', 'fileLink', 'description', 'optactsAvailability', 'charactersAvailability', 'averageCompletion', 'allowDecisionFeedback', 'allowReflection']
      }, 'user/flow/' + this.currentUser._id + '/search', (data, error) => {
        if (data) {
          resolve(data.results);
        }
        if (error) {
          reject(error);
        }
      });
    });
  }

  switchTabs(showAvailable: boolean) {
    this.isAvailableSelected = showAvailable;
    if (showAvailable) {
      this.flows = this.availableFlows;
    }
    else {
      this.flows = this.pastFlows;
    }
  }

  // search(searchText: string) {
  //   searchText = searchText.toLowerCase();
  //   this.availableFlows = this.allAvailableFlows.filter(i => i.description.toLowerCase().indexOf(searchText) !== -1 || i.name.toLowerCase().indexOf(searchText) !== -1);
  //   this.pastFlows = this.allPastFlows.filter(i => i.description.toLowerCase().indexOf(searchText) !== -1 || i.name.toLowerCase().indexOf(searchText) !== -1);
  //   this.switchTabs(this.isAvailableSelected);
  // }

  startFlow(flow: any) {
    console.log(flow);
    let findInstance = flow.instances.find(i => i.flowId._id === flow._id && i.status?.toLowerCase() === 'in progress');
    if (findInstance) {
      this.router.navigate(['flow/' + flow._id + '/instance/' + findInstance._id]);
    }
    else {
      if (flow.charactersAvailability === 'randomize' && flow.optactsAvailability === 'randomize') {
        let chosenCharacter = flow.characterIds[Math.floor(Math.random() * flow.characterIds.length)];
        let chosenOpeningAct = flow.openingActIds[Math.floor(Math.random() * flow.openingActIds.length)];
        this.createInstance(flow, chosenCharacter, chosenOpeningAct);
      }
      else {
        const dialog = this.dialog.open(FlowCharacterSelectionDialogComponent, {
          data: {
            characterList: flow.characterIds,
            showStepCharacters: flow.charactersAvailability === 'randomize' || flow.characterIds?.length === 0 ? false : true,
            openingActs: flow.openingActIds,
            showStepOpeningAct: flow.optactsAvailability === 'randomize' || flow.openingActIds?.length === 0 ? false : true,
          },
          width: 'fit-content'
        });

        dialog.afterClosed().subscribe(value => {
          if (value) {
            let chosenCharacter = [];
            let chosenOpeningAct = [];

            if (flow.charactersAvailability === 'randomize') {
              if (flow.characterIds?.length)
                chosenCharacter = flow.characterIds[Math.floor(Math.random() * flow.characterIds.length)];
            }
            else
              chosenCharacter = value[0];

            if (flow.optactsAvailability === 'randomize') {
              if (flow.openingActIds?.length)
                chosenOpeningAct = flow.openingActIds[Math.floor(Math.random() * flow.openingActIds.length)];
            }
            else
              chosenOpeningAct = value[1];

            this.createInstance(flow, chosenCharacter, chosenOpeningAct);
          }
        });
      }
    }
  }

  private createInstance(flow, chosenCharacter, chosenOpeningAct) {
    this.requestService.postRequest({
      points: 0,
      score_percentage: 0,
      versionId: flow.versionId,
      flow: flow.flow,
      status: 'in progress',
      allowReflection: flow.allowReflection,
      allowDecisionFeedback: flow.allowDecisionFeedback,
      userId: {
        _id: this.currentUser._id,
        name: this.currentUser.name
      },
      characterId: chosenCharacter,
      flowId: {
        _id: flow._id,
        name: flow.name
      },
      organizationId: flow.organizationId,
      openingActId: chosenOpeningAct
    }, 'instance', (data: any, error: any) => {
      if (data) {
        // this.router.navigate(['flow/' + data.results._id]);
        this.router.navigate(['flow/' + data.results.flowId._id + '/instance/' + data.results._id]);
      }
      else if (error) {
        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
      }
    });
  }

  showInstances(idFlow: string) {
    const dialog = this.dialog.open(InstanceListDialogComponent, {
      data: {
        flowId: idFlow
      },
      width: '60vw',
      disableClose: true
    });
  }
}