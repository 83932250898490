import { Injectable } from '@angular/core';
import confetti from 'canvas-confetti';

/*
    Shows confetti animations, just for fun.
    See: https://www.kirilv.com/canvas-confetti/
*/
@Injectable({
    providedIn: 'root'
})
export class ConfettiService {

    private animationId: any;

    constructor() { }

    public canon(): void {
        confetti({
            angle: this.randomInRange(55, 125),
            spread: this.randomInRange(50, 70),
            particleCount: this.randomInRange(50, 100),
            origin: { y: 0.6 }
        });
    }

    private randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    party() {
        var end = Date.now() + (15 * 1000);

        // go Buckeyes!
        var colors = ['#bb0000', '#ffffff'];
        let animationIds = [];

        (function frame() {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors
            });

            if (Date.now() < end) {
                animationIds.push(requestAnimationFrame(frame));
            }
        }());

        this.animationId = animationIds;
    }

    stop() {
        this.animationId.forEach(element => {
            cancelAnimationFrame(element);
        });
    }
}