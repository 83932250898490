import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";

@Component({
    selector: 'app-instance',
    templateUrl: './instance.component.html',
    styleUrls: ['./instance.component.scss']
})
export class InstanceComponent implements OnInit, OnDestroy {

    private subscriptions: any[] = <any>[];
    private openingAct: any = undefined;
    private selectedOptions: any = [];

    public instance: any = {};
    public step: any = {};
    public character: any = {};
    public selectedOption: any = {};
    public selectedOptionReflection: any = undefined;
    public selectedOptionFeedback: any = undefined;
    public history: any = [];
    public previousNodeId: string = undefined;
    public node: any = undefined;
    public currentUser: any = undefined;
    public isPreview: boolean = false;
    public score: number = 0;
    public showFeedback: boolean = false;
    public showCongrats: boolean = false;

    constructor(private activatedRoute: ActivatedRoute, private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private router: Router, private changeDetector: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((value) => {
            if (value) {
                this.currentUser = value;
            }
        }));

        this.subscriptions.push(this.activatedRoute.params.subscribe(async (params: any) => {
            if (params.hasOwnProperty('instanceId')) {
                if (this.activatedRoute.snapshot.data['preview']) {
                    this.isPreview = true;
                    await this.getInstance(params.instanceId).then((data: any) => {
                        this.instance = data;
                        this.history = data.flow.history;
                        this.score = data.points;
                        this.selectedOptions = data.flow.selectedOptions;
                    }).catch(error => {
                        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                    });
                    let nodeId = this.instance.flow.nodes.find(i => i.type === 'start')?.id;
                    if (nodeId) {
                        this.instance.currentNodeId = undefined;
                    }
                    await this.getCharacter(this.instance.characterId._id).then((data) => {
                        this.character = data;
                    }).catch(error => {
                        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                    });
                    await this.getOpeningAct(this.instance.openingActId._id).then((data: any) => {
                        this.openingAct = {
                            content: this.replaceX(this.character.name, this.character.gender, data.results.maleContent, data.results.femaleContent, data.results.otherContent)
                        };
                    }).catch(error => {
                        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                    });
                    this.populateStep();
                }
                else {
                    await this.getInstance(params.instanceId).then((data: any) => {
                        this.instance = data;
                        this.history = data.flow.history;
                        this.score = data.points;
                        this.selectedOptions = data.flow.selectedOptions;
                    }).catch(error => {
                        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                    });
                    if (this.instance.status === 'completed')
                        this.router.navigate(['/flow/' + this.instance.flowId._id + '/instance/' + params.instanceId + '/preview']);
                    await this.getCharacter(this.instance.characterId._id).then((data) => {
                        this.character = data;
                    }).catch(error => {
                        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                    });
                    await this.getOpeningAct(this.instance.openingActId._id).then((data: any) => {
                        this.openingAct = {
                            content: this.replaceX(this.character.name, this.character.gender, data.results.maleContent, data.results.femaleContent, data.results.otherContent)
                        };
                    }).catch(error => {
                        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
                    });
                    this.populateStep();
                }
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    // private getCurrenStep() {
    //     let node = this.instance.flow.nodes.find(i => i.id === this.instance.currentNodeId);
    //     this.populateStep(node);
    // }

    private getInstance(instanceId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord(instanceId, 'instance', async (data, error) => {
                if (data)
                    resolve(data.results);
                if (error) {
                    reject(error);
                }
            });
        });
    }

    private getCharacter(characterId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord(characterId, 'character', (data: any, error: any) => {
                if (data) {
                    resolve(data.results);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    private replaceX(characterName, gender, male, female, other): string {
        if (gender === 'male')
            return male?.replace(/\[X\]/g, characterName);
        else if (gender === 'female')
            return female?.replace(/\[X\]/g, characterName);
        else
            return other?.replace(/\[X\]/g, characterName);
    }

    private getOpeningAct(openingActId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord(openingActId, 'openingacts', (data: any, error: any) => {
                if (data) {
                    resolve(data);
                }
                if (error)
                    reject(error);

                // this.populateStep();
            });
        });
    }

    next() {
        if (!this.instance.currentNodeId)
            this.instance.currentNodeId = this.instance.flow.nodes.find(i => i.type === 'start')?.id;

        let postBody: any = {
            nodeId: this.instance.currentNodeId,
            instanceId: this.instance._id,
            isPreview: this.isPreview
        };

        if (this.selectedOption) {
            postBody.selectedOption = this.selectedOption.fieldId;
        }

        if (this.selectedOptionReflection) {
            postBody.reflection = this.selectedOptionReflection;
        }

        this.requestService.postRequest(postBody, 'instance/saveState', (data, error) => {
            // console.log(data, this.instance)
            // let node = this.instance.flow.nodes.find(i => i.id === data.results);
            this.instance.currentNodeId = data.results.nextNode;
            this.history = data.results.history;
            this.score = data.results.points;
            this.selectedOptions = data.results.selectedOptions;
            this.selectedOption = {};
            this.populateStep();
            // console.log(this.step)
            // this.selectedOption = undefined;
        });
    }

    previous() {
        this.selectedOption = {};
        let findNodeIndex = this.history?.findIndex(i => i === this.node.id);
        if (findNodeIndex !== -1) {
            Object.assign(this.selectedOption, this.selectedOptions.find(i => i.nodeId === this.history[findNodeIndex - 1]) || {});
            // console.log(this.selectedOption, this.selectedOptions)
            this.changeDetector.detectChanges();
        }
        this.instance.currentNodeId = this.previousNodeId;
        this.populateStep();
        this.requestService.postRequest({
            currentNodeId: this.previousNodeId
        }, 'instance/' + this.instance._id, (data, error) => {

        });
    }

    private async populateStep() {
        this.node = this.instance.flow.nodes.find(i => i.id === this.instance.currentNodeId);
        let step: any = {}
        if (this.node?.type === 'start' || !this.instance.currentNodeId) {
            if (!this.node)
                this.node = {};

            this.node.type = 'start';
            this.instance.currentNodeId = this.instance.flow.nodes.find(i => i.type === 'start')?.id;
            this.step = this.openingAct;
        }
        else if (this.node?.type === 'condition') {
            step.content = this.replaceX(this.character.name, this.character.gender, this.node.name.male, this.node.name.female, this.node.name.other);

            step.options = [];
            this.node.conditions.forEach(option => {
                let opt = this.node.metadata.fields.find(i => i.fieldId === option.fieldId);

                step.options.push({
                    fieldId: opt.fieldId,
                    label: this.replaceX(this.character.name, this.character.gender, opt.fieldValue.male, opt.fieldValue.female, opt.fieldValue.other),
                    score: opt.fieldScore,
                    tags: opt.fieldTags,
                    feedback: opt.feedback
                });
            });

            this.selectedOption = {};
            let node = this.selectedOptions.find(i => i.nodeId === this.node.id);
            if (node) {
                this.selectedOption = {
                    fieldId: node.fieldId,
                    score: node.fieldScore
                };
                this.selectedOptionReflection = node.reflection;
                this.selectedOptionFeedback = this.node.metadata.fields.find(i => i.fieldId === node.fieldId)?.feedback;
            }

            step.background = this.node.metadata?.media[0]?.background?.url;
            step.audio = this.node.metadata?.media[0]?.audio?.url;
            step.video = this.node.metadata?.media[0]?.video?.url;

            this.step = step;
        }
        else if (this.node?.type === 'state') {
            step.content = this.replaceX(this.character.name, this.character.gender, this.node.name.male, this.node.name.female, this.node.name.other);
            step.background = this.node.metadata?.media[0]?.background?.url;
            step.audio = this.node.metadata?.media[0]?.audio?.url;
            step.video = this.node.metadata?.media[0]?.video?.url;

            this.step = step;
        }
        else if (this.node?.type === 'end' && !this.isPreview) {
            let conditionNodes = this.instance.flow.nodes.filter(i => i.type === 'condition' && i.metadata.fields.find(j => j.feedback));
            if (conditionNodes.length)
                this.showFeedbackBackdrop();
            else
                this.showCongratsBackdrop();
        }
        // else if (this.node?.type === 'end') {
        //     step.content = 'Completed';
        //     this.step = step;
        // }

        let findNodeIndex = this.history?.findIndex(i => i === this.node.id);
        if (findNodeIndex !== -1 && findNodeIndex > 0)
            this.previousNodeId = this.history[findNodeIndex - 1];
    }

    private showFeedbackBackdrop() {
        this.showFeedback = true;
    }

    private showCongratsBackdrop() {
        this.showCongrats = true;
    }
}