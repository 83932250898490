import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StoreService } from './core/services/store.service';
import { RequestService } from './core/services/request.service';
import { LayoutUtilsService } from './core/services/layout-utils.service';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GlobalErrorHandler, RollbarService, rollbarFactory } from './core/services/globar-error.handler';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlowCharacterSelectionDialogComponent } from './features/flow/flow-character-selection-modal/flow-character-selection-modal.component';
import { MatStepperModule } from '@angular/material/stepper';
import { InstanceComponent } from './features/instance/instance.component';
import { InstanceListDialogComponent } from './features/instance-list-modal/instance-list-modal.component';
import { NgChartsModule } from 'ng2-charts';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { InsightsComponent } from './features/insights/insights.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ConfettiService } from './core/services/confetti.service';
import { FeedbackComponent } from './features/feedback/feedback.component';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/', '.json');
};

@NgModule({
  declarations: [
    AppComponent, DashboardComponent, FlowCharacterSelectionDialogComponent, InstanceComponent, InstanceListDialogComponent, InsightsComponent, FeedbackComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CustomMaterialModule.forRoot(),
    CoreModule,
    SharedModule,
    AppRoutingModule,
    MatGridListModule,
    NgChartsModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatStepperModule
  ],
  providers: [
    StoreService,
    RequestService,
    ConfettiService,
    LayoutUtilsService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
