import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FlowService {

    public flowListSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() { }
}