import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";
import { CustomImageCropperDialogComponent } from "../image-cropper-dialog/image-cropper-dialog.component";

@Component({
    selector: 'app-edit-profile-dialog',
    templateUrl: './edit-profile-dialog.component.html',
    styleUrls: ['./edit-profile-dialog.component.scss']
})

export class EditProfileDialogComponent implements OnInit, OnDestroy {

    private currentUser: any;
    public imageExtension: string = 'png';
    public allowedExtensions: string[] = ['jpeg', 'jpg', 'png', 'gif'];
    public defaultPictureLink: string = 'assets/images/profile.png';
    public pictureLink: string = JSON.parse(JSON.stringify(this.defaultPictureLink));
    public originalData: any = undefined;
    public loading: boolean = false;
    public imageChanged: any = undefined;
    public email: string = undefined;

    public firstNameFormControl = new FormControl('', Validators.required);
    public lastNameFormControl = new FormControl('', Validators.required);

    private subscriptions: any[] = <any>[];

    constructor(private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private dialog: MatDialog, private dialogRef: MatDialogRef<EditProfileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((value) => {
            if (value) {
                this.currentUser = value;
                this.getUser();
            }
        }));
        // console.log(this.currentUser)
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private validate(): boolean {
        this.firstNameFormControl.markAsTouched();
        this.lastNameFormControl.markAsTouched();
        return this.firstNameFormControl.hasError('required') || this.lastNameFormControl.hasError('required');
    }

    getUser() {
        this.loading = true;
        this.requestService.getRecord(this.currentUser._id + '/' + this.requestService.orgId, 'user', (data: any, error: any) => {
            if (data) {
                this.firstNameFormControl.setValue(data.results.firstName);
                this.lastNameFormControl.setValue(data.results.lastName);
                this.pictureLink = data.results.pictureLink || this.defaultPictureLink;
                this.email = data.results.email;
            }
            if (error)
                this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
            this.loading = false;
        });
    }

    updateUser() {
        if (!this.validate()) {
            this.loading = true;
            this.requestService.updateRecord({
                firstName: this.firstNameFormControl.value,
                lastName: this.lastNameFormControl.value
            }, 'user', this.currentUser._id + '/' + this.requestService.orgId, (data: any, error: any) => {
                if (data) {
                    if (this.imageChanged) {
                        const img: any = this.dataURLtoFile(this.imageChanged, this.currentUser._id + '.' + this.imageExtension);
                        this.uploadImage(img, this.currentUser._id, () => {
                            this.dialogRef.close();
                            this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
                        });
                    }
                    else {
                        this.dialogRef.close();
                        this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
                    }
                }
                if (error)
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                this.loading = false;
            });
        }
    }

    public showImageCropper() {
        const dialog = this.dialog.open(CustomImageCropperDialogComponent, {
            disableClose: false,
            autoFocus: true,
            width: '70vw',
            data: {
                title: this.translate.instant('Crop Image'),
                image: this.pictureLink
            }
        });
        dialog.afterClosed().subscribe((result: any) => {
            if (result) {
                this.imageChanged = result;
                this.pictureLink = result;
            }
        });
    }

    public uploadImage(file: any, id: string = this.currentUser._id, callback?: any) {
        this.loading = true;
        let fileObj = {
            originalFile: file,
            text: file.name
        }
        this.requestService.onUploadUserImage(fileObj, id)
            .subscribe(
                (results: any) => {
                    this.pictureLink = results.results.pictureLink;
                    this.loading = false;
                    this.layoutUtilsService.showNotification(this.translate.instant('Image uploaded successfully'), this.translate.instant('Dismiss'));

                    if (callback)
                        callback();
                },
                (error: any) => {
                    this.pictureLink = JSON.parse(JSON.stringify(this.defaultPictureLink));
                    this.loading = false;
                    this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
                }
            );
    }

    closeDialog(data?: any) {
        this.dialogRef.close(data);
    }

    dataURLtoFile(dataurl: any, filename: any) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
}