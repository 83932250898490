<h1 mat-dialog-title>{{'My Assessments' | translate}}</h1>
<div mat-dialog-content style="overflow-y:hidden">
    <mat-spinner [diameter]="50" class="loader" *ngIf="loading"></mat-spinner>
    <div class="conatiner" *ngIf="!loading">
        <table mat-table [dataSource]="datasource" class="mat-elevation-z8 table" *ngIf="datasource">
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef> {{'Started At' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'medium'}} </td>
            </ng-container>
            <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef> {{'Ended At' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.endedAt | date: 'medium'}} </td>
            </ng-container>
            <ng-container matColumnDef="points">
                <th mat-header-cell *matHeaderCellDef> {{'Points' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.points}} </td>
            </ng-container>
            <ng-container matColumnDef="score">
                <th mat-header-cell *matHeaderCellDef> {{'Score %' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.status === 'completed' ? element.score_percentage :
                    '-'}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> {{'Status' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="capitalize"> {{element.status}} </td>
            </ng-container>
            <ng-container matColumnDef="character">
                <th mat-header-cell *matHeaderCellDef> {{'Character' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="capitalize"> {{element.characterId.name}} </td>
            </ng-container>
            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;"></th>
                <td mat-cell *matCellDef="let element" class="tools">
                    <mat-icon (click)="viewInstance(element._id, element.status)" [title]="'View Instance' | translate">
                        visibility</mat-icon>
                    <mat-icon (click)="deleteInstance(element._id)" [title]="'Delete Instance' | translate">
                        delete
                    </mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator #paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons class="paginator-shadow"
        (page)="getInstances($event)" [pageIndex]="pageIndex" [pageSize]="pageSize">
    </mat-paginator>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{'Close' |
        translate}}</button>
</div>