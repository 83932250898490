import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { FeedbackComponent } from './features/feedback/feedback.component';

import { InsightsComponent } from './features/insights/insights.component';
import { InstanceComponent } from './features/instance/instance.component';
import { LayoutComponent } from './shared/layout/layout.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'insights', component: InsightsComponent },
      { path: 'flow/:flowId/instance/:instanceId', component: InstanceComponent },
      { path: 'flow/:flowId/instance/:instanceId/preview', component: InstanceComponent, data: { preview: true } },
      { path: 'flow/:flowId/instance/:instanceId/feedback', component: FeedbackComponent },
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
