<h1 mat-dialog-title>{{'Start Training' | translate}}</h1>
<div mat-dialog-content style="overflow-y:hidden">
    <mat-stepper labelPosition="bottom" #stepper style="max-width: 60vw; ">
        <mat-step *ngIf="showStepCharacters">
            <ng-template matStepLabel>{{'Select Characters' | translate}}</ng-template>
            {{'Drag your primary character to the right side of the screen or click Random
            Selection to let the
            system choose for you' | translate}}
            <div class="container">
                <h2>{{'Character List' | translate}}</h2>
                <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="characterList"
                    [cdkDropListConnectedTo]="[doneList]" class="list" (cdkDropListDropped)="drop($event)">
                    <div class="box" *ngFor="let item of characterList" cdkDrag
                        [cdkDragDisabled]="isCharactersDisabled">
                        <img [src]="item.pictureLink || 'assets/images/profile.png'" />
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="container">
                <h2>{{'Primary Character' | translate}}</h2>
                <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="primaryCharacter"
                    [cdkDropListConnectedTo]="[todoList]" class="list" (cdkDropListDropped)="drop($event)">
                    <div class="box" *ngFor="let item of primaryCharacter" cdkDrag>
                        <img [src]="item.pictureLink || 'assets/images/profile.png'" />
                        {{item.name}}
                    </div>
                </div>
            </div>
            <!-- <div class="align-right">
                <button mat-button matStepperNext>Next</button>
            </div> -->
        </mat-step>
        <mat-step *ngIf="showStepOpeningAct">
            <ng-template matStepLabel>{{'Select Opening Act' | translate}}</ng-template>
            <div class="container">
                <h2>{{'Opening Act List' | translate}}</h2>
                <div cdkDropList #openingList="cdkDropList" [cdkDropListData]="openingActList"
                    [cdkDropListConnectedTo]="[selectedActsList]" class="list" (cdkDropListDropped)="drop($event)">
                    <div class="box" *ngFor="let item of openingActList" cdkDrag
                        [cdkDragDisabled]="isOpeningActDisabled">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="container">
                <h2>{{'Selected Opening Act' | translate}}</h2>
                <div cdkDropList #selectedActsList="cdkDropList" [cdkDropListData]="selectedOpeningAct"
                    [cdkDropListConnectedTo]="[openingList]" class="list" (cdkDropListDropped)="drop($event)">
                    <div class="box" *ngFor="let item of selectedOpeningAct" cdkDrag>
                        {{item.name}}
                    </div>
                </div>
            </div>
            <!-- <div class="align-right">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
            </div> -->
        </mat-step>
        <!-- <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <p>You are now done.</p>
        </mat-step> -->
    </mat-stepper>
</div>
<div mat-dialog-actions align="end">
    <button mat-button *ngIf="showStepOpeningAct && showStepCharacters && stepper.selectedIndex !== 1"
        (click)="stepper.next()" class="mat-raised-button"
        [disabled]="showStepOpeningAct && !primaryCharacter.length">{{'Next' |
        translate}}</button>
    <button mat-button *ngIf="showStepOpeningAct && showStepCharacters && stepper.selectedIndex !== 0"
        (click)="stepper.previous()" class="mat-raised-button">{{'Previous' | translate}}</button>
    <button mat-button mat-dialog-close class="mat-raised-button">{{'Close' | translate}}</button>
    <button mat-button *ngIf="showStepCharacters && stepper.selectedIndex === 0" (click)="randomizeCharacters()"
        class="mat-raised-button">{{'Random Selection' |
        translate}}</button>
    <button mat-button
        *ngIf="showStepOpeningAct && (stepper.selectedIndex === 1 || (!showStepCharacters && stepper.selectedIndex === 0))"
        (click)="randomizeOpeningActs()" class="mat-raised-button">{{'Random Selection' |
        translate}}</button>
    <button mat-button
        *ngIf="(showStepOpeningAct && showStepCharacters && stepper.selectedIndex === 1) || (!showStepCharacters && showStepOpeningAct && stepper.selectedIndex === 0) || (showStepCharacters && !showStepOpeningAct && stepper.selectedIndex === 0)"
        (click)="startTraining()" class="mat-raised-button"
        [disabled]="(showStepCharacters && !primaryCharacter.length) || (showStepOpeningAct && !selectedOpeningAct.length)">{{'Start
        Training' |
        translate}}</button>
</div>