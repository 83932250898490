import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';

import { SpinnerService } from './../services/spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

    private requestsInProgress = 0;

    constructor(private spinnerService: SpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.spinnerService.show();
        this.requestsInProgress++;

        return next.handle(request).pipe(
            // Hide the spinner when the request is complete (either success or error)
            finalize(() => {
                this.requestsInProgress--;

                // Hide the spinner only if there are no more requests in progress
                if (this.requestsInProgress === 0) {
                    this.spinnerService.hide();
                }
            })
        );
    }
}
